<template>
  <div>
    <!-- Display loading spinner while waiting for handleLogin -->
    <div v-if="loading" class="fixed inset-0 bg-white flex items-center justify-center z-50">
      <div class="loader"></div> <!-- Spinner class -->
    </div>

    <!-- Navbar stays persistent across all routes -->
    <NavbarComponent v-if="user" :user="user" :showHeader="showHeader" @logout="handleLogout" />

    <!-- Main content changes based on the route -->
    <router-view :user="user" @login="handleLogin" />
  </div>
</template>

<script>
import NavbarComponent from './components/NavbarComponent.vue';
import { setCookie, eraseCookie, getCookie } from './helpers/cookies';

async function createUser(email) {
  const response = await fetch(`${process.env.VUE_APP_API_URL}/api/createUsrOnLogin/${email}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Error creating user');
  } else {
    const responseBody = await response.json();
    setCookie('token', responseBody.token, 7);
  }
}

export default {
  components: {
    NavbarComponent
  },
  data() {
    return {
      user: JSON.parse(getCookie('user')) || null,
      loading: false // Add loading state
    };
  },
  computed: {
    showHeader() {
      // Return false if the current path is '/purchase', otherwise true
      return this.$route.path !== '/purchase';
    },
  },
  created() {
    const token = getCookie('token');
    if (this.user && token) {
      this.$router.push('/requests');
    }
  },
  methods: {
    async handleLogin(userData) {
      this.loading = true; // Show loading spinner
      this.user = userData;
      setCookie('user', JSON.stringify(userData.email), 7);
      localStorage.setItem('user', JSON.stringify(userData));
      await createUser(userData.email)
      this.loading = false; // Hide loading spinner after login is complete
      this.$router.push('/requests');
    },
    handleLogout() {
      this.user = null;
      eraseCookie('user');
      localStorage.removeItem('user');
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>
/* Spinner styles */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
