<template>
  <div class="flex flex-col lg:flex-row h-screen">
    <!-- Left section for login -->
    <div class="w-full lg:w-1/2 text-white flex flex-col items-center justify-center h-screen"
      style="background-color: #012241;">
      <h1 class="text-4xl md:text-5xl font-semibold text-center">Iniciar Sesión</h1>
      <div id="login" class="mx-8 md:mx-40 w-full flex flex-col items-center">
        <hr class="my-6 md:my-8 border-gray-300 w-1/2" />
        <GoogleLogin :callback="callback" prompt auto-login class="my-4"></GoogleLogin>
        <!-- Form section -->
        <!--
        <hr class="my-6 md:my-8 border-gray-300" />
        <form method="POST" class="flex flex-col items-center w-full px-4 md:px-0">
          <label for="email" class="mb-1 text-lg text-left w-full">Email</label>
          <input type="email" name="email" class="h-12 rounded-md mb-4 text-black px-2 w-full" required />
          <label for="password" class="mb-1 text-lg text-left w-full">Contraseña</label>
          <input type="password" name="password" class="h-12 rounded-md mb-4 text-black px-2 w-full" required />
          <p class="underline hover:text-gray-200 cursor-pointer mb-4 text-left w-full">Olvidé mi contraseña</p>
          <button class="p-2 rounded-md hover:bg-blue-300 font-bold w-full md:w-auto" style="background-color: #13B07F;">Iniciar
            Sesión</button>
        </form>
        -->
      </div>
    </div>

    <!-- Right section for the image and text -->
    <div class="bg-white w-full lg:w-1/2 flex flex-col items-center justify-center p-6 lg:p-12">
      <img src="../assets/img/wapi-banner.png" alt="Wapi Logo" class="w-3/4 lg:w-2/4 mx-auto mt-12 mb-8">
      <p class="text-2xl md:text-4xl text-center font-bold mx-4 md:mx-12 mb-4" style="color: #2a6465">Firmá todos tus
        documentos desde
        cualquier lugar con <span style="color: #012241;">WapiFirma</span></p>
      <img src="../assets/img/login.png" alt="Wapi Logo" class="w-2/4 lg:w-1/4 mx-auto mb-6">
      <p class="text-2xl md:text-4xl text-center font-bold mx-4 md:mx-8" style="color: #2a6465;">Simple. Rápido. Legal.
      </p>
    </div>
  </div>
</template>

<script>
import { decodeCredential } from 'vue3-google-login';

export default {
  methods: {
    callback(response) {
      const userData = decodeCredential(response.credential);
      if (userData) {
        localStorage.setItem('user', JSON.stringify(userData)); // Store user data in local storage
        this.$emit('login', userData);
      } else {
        console.error('Authentication failed');
      }
    }
  }
}
</script>
